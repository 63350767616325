<template>
  <div class="wrap" :style="verConfig.loginBG && { backgroundImage: `url(${verConfig.loginBG})` }
    ">
    <div class="logo">
      <!-- <Img
        height="100"
        :src="require(`@tt/assets/ico/${ver}.png`)"
        fit="scale-down"
      /> -->
      <img :src="require('@/assets/logo/login.png')" class="logo_img" alt="">
    </div>
    <div class="deline">
      <div class="left"></div>
      <div class="text">{{ $t("partner") }}</div>
      <div class="right"></div>
    </div>
    <div class="partners">
      <img :src="require(`@tt/assets/login/patner/all.png`)" alt="" class="partner_img">
    </div>
    <div class="form_wrap_bar">
      <LoginForm :formList="formList" cancelText="Login.registrasi" confirmText="Login.loginButton"
        readText="login.rememberPassword" :getRemember="true" @cancel="toRegiste" @submit="onSubmit">
      </LoginForm>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VImg, Toast } from "vant";
import LoginForm from "./components/form.vue";
import { SEND_SMS_CODE, LOGIN } from "@/api";
import { getNewPhone } from "@/utils/tools";
import { $post } from "../../utils/request";
export default {
  components: {
    Icon,
    VImg,
    LoginForm,
  },
  data() {
    return {
      formList: [
        {
          key: "userphone",
          flag: true,
          icon: "icon-yonghuming",
          label: "Login.nameInput",
          rules: [{ required: true, message: this.$t("hint.usernameEnter") }],
        },
        {
          label: "Login.pwdInput",
          type: "password",
          key: "userpass",
          icon: "icon-zhanghumima",
          flag: true,
          rules: [{ required: true, message: this.$t("hint.passwordEnter") }],
          isPassword: true,
        },
      ],
    };
  },
  methods: {
    onSubmit(values, read) {
      Toast.loading({ duration: 0 });
      LOGIN(values) // http请求
        .then((r) => {
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.$cookie.set("the-pwd-state", this.checked);
            if (read) {
              this.$cookie.set(
                "the-pwd-obj",
                JSON.stringify({
                  userphone: values.userphone,
                  userpass: values.userpass,
                })
              );
            } else {
              this.$cookie.remove("the-pwd-obj");
            }
          } else {
            Toast.clear();
            Toast(r.data.msg);
          }
          return r.data.ret;
        })
        .then(async (ret) => {
          if (ret == 1) {
            this.$store.dispatch("getSystemConfig");
            try {
              const res = await $post("/users/info");
              const { ret, msg, data } = res.data;
              if (ret == 1) {
                // this.LoadingShow = false;
                Toast.clear();
                this.$router.replace({ name: "goods" });
                this.$cookie.set("userInfo", JSON.stringify(data));
              }
            } catch (error) {
              console.error("登录错误", error);
              Toast.clear();
            }
          }
        })
        .catch((e) => {
          Toast.clear();
          console.log(e);
        });
    },
    toRegiste() {
      this.$router.push("/registrasi");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: var(--bg);
  min-height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .deline {
    padding: 0 calc(17rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(30rem / 16);
    color: var(--light);

    .left {
      flex: 1;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .text {
      margin: 0 calc(10rem / 16);
    }

    .right {
      .left();
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
  }

  .partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: calc(15rem / 16) calc(17rem / 16) calc(15rem / 16);
    > .partner_img{
      // height: 60px;
      width: 100%;
    }
  }

  .form_wrap_bar {
    margin: calc(17rem / 16);
    background-color: rgba(255, 255, 255, .3);
    padding: calc(1rem / 16) 0 calc(30rem / 16);
    border-radius: 8px;
  }

  .nav {
    // height: calc(50rem / 16);
    color: var(--textColor);
    display: flex;
    align-items: center;
    margin: calc(26rem / 16) calc(17rem / 16) 0;

    .back {
      transform: rotate(90deg);
    }

    .title {
      font-size: calc(24rem / 16);
      margin-left: calc(14rem / 16);
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-top: calc(26rem / 16);
    &_img{
      height: calc(60rem / 16);
      display: block;
    }
  }
}
</style>